<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">合同管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">我的合同</span>
        <img src="../../assets/right.png" alt />
        <span class="center">编辑合同信息</span>
        <img src="../../assets/right.png" alt />
        <span class="center">合同详情</span>
      </div>
      <el-button type="primary" size="small" @click="save" class="top_btn">保存</el-button>
    </div>
    <el-card>
      <div style="display: flex; margin-top: 20px">
        <div>
          <h2>签署文件</h2>
          <div class="file-list">
            <div
              v-for="(item, i) in files"
              :key="i"
              @click="loadPdf(item)"
              :class="item.isSelect ? 'active' : ''"
            >
              <el-tooltip class="item" effect="dark" content="点击选中签署文件" placement="bottom">
                <span>
                  <i class="iconfont iconpdf-fill" style="margin-right: 4px; font-size: 18px"></i>
                  {{ item.name }}
                </span>
              </el-tooltip>
            </div>
          </div>
          <h2>签署方</h2>
          <div v-for="(item, i) in signers" :key="i">
            <h3>{{ item.name }}</h3>
            <div class="drag-box">
              <el-tooltip class="item" effect="dark" content="拖拽印章至需盖章处" placement="bottom">
                <span class="cricleSeal" draggable="true" @dragstart="drag($event, item, '1')">印章</span>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="拖拽骑缝章至文件处" placement="bottom">
                <span class="crossSeal" draggable="true" @dragstart="drag($event, item, '2')">骑缝章</span>
              </el-tooltip>
            </div>
            <!-- <ul>
            <li draggable="true" @dragstart="drag($event, item, '1')">印章</li>
            <li draggable="true" @dragstart="drag($event, item, '2')">
              骑缝章
            </li>
            </ul>-->
          </div>
        </div>
        <div style="flex-grow: 1">
          <el-pagination
            style="text-align: center; margin-top: 20px"
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="1"
            layout="total, prev, pager, next, jumper"
            :total="numPages"
          ></el-pagination>
          <div
            class="content"
            :style="{ width: pageWidth + 'px', height: pageHeight + 'px' }"
            @dragover="allowDrop($event)"
            @drop="drop($event)"
          >
            <pdf
              style="min-width: 90px"
              ref="pdf"
              v-if="currentFile"
              :src="currentFile.url"
              :page="currentPage"
              v-loading="loading"
              element-loading-text="内容加载中..."
            ></pdf>

            <div v-for="(item, i) in seals" :key="i">
              <div
                class="seal"
                @mousedown="activeSeals = item"
                @mousemove="sealMousemove($event)"
                :class="{
                  sealAll:
                    currentPage &&
                    item.type === '2' &&
                    item.fileId == currentFile.fileId,
                  sealShow:
                    currentPage &&
                    item.page === currentPage &&
                    item.fileId == currentFile.fileId,
                  seal2: item.type === '2',
                }"
                :style="{
                  top: pageHeight - item.y + 'px',
                  left: item.x + 'px',
                }"
              >
                <div style="margin-top: 27px">印章</div>
                <div class="seal-title">
                  {{ item.subjectName }}
                  <a
                    @click="delSeal(item)"
                    style="color: #409eff; cursor: pointer"
                  >删除</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </el-card>
  </div>
</template>
<script>
import * as _ from 'lodash';
import pdf from 'vue-pdf';
//接口：
import { api } from '/src/api/base';
//新增接口
export const ContractAdd = api('/contract')('contract.add.json');
//查询详情接口
export const ContractDetail = api('/contract')('contract.describe.json');
//编辑接口
export const ContractEdit = api('/contract')('contract.edit.json');
//删除接口
export const ContractDel = api('/contract')('contract.del.json');
//查询详情接口
export const ContractTemplateDetail = api('/contractTemplate')(
  'contractTemplate.describe.json'
);
export const ContractTemplateView = api('/contractTemplate')(
  'contractTemplate.view.json'
);
export const ContractTemplateEdit = api('/contractTemplate')(
  'contractTemplate.edit.json'
);
import { parseFilePath } from '/src/lib/dev';
export default {
  components: {
    pdf
  },
  data () {
    return {
      pageWidth: 0,
      pageHeight: 0,
      sealWidth: 80,
      numPages: 0, // pdf 总页数
      currentPage: 0,
      activeSeals: null,
      currentFile: null,
      signers: [
        // {
        //   name: "甲方",
        //   account: "123"
        // },
        // {
        //   name: "乙方",
        //   account: "456"
        // }
      ],
      files: [
        // {
        //   name: "合同文件1",
        //   url:
        //     "http://file.lgbfss.com/public_assets/contract/template/6f686de0-8ae9-11eb-a5ed-f54795e355b4/兴瑞恒通（江西）-综合服务协议（企业端）21.03.22(1).pdf",
        //   fileId: "1"
        // },
        // {
        //   name: "合同文件1",
        //   url:
        //     "http://file.lgbfss.com/public_assets/contract/complate/b6a2fed0-8c80-11eb-8a00-c1159c9d3c60.pdf",
        //   fileId: "2"
        // }
      ],
      seals: [],
      loading: true,
      type: ''
    };
  },
  async mounted () {
    if (this.$route.query.type === 'template') {
      this.type = 'template'
      await this.loadContractTemplate();
    } else {
      await this.loadContract();
    }
    await this.loadPdf(this.files[0]);

    window.addEventListener('mouseup', () => {
      this.activeSeals = null;
    });
  },
  methods: {
    async save () {
      if (this.$route.query.type === 'template') {
        await ContractTemplateEdit({
          code: this.$route.query.code,
          config: JSON.stringify(this.seals)
        });

        this.$router.push({
          path: '/system/contract/contractTemplate',
          query: { code: this.$route.query.code }
        });
      } else {
        await ContractEdit({
          code: this.$route.query.code,
          config: JSON.stringify(this.seals)
        });

        this.$router.push({
          path: '/system/contract/add',
          query: { code: this.$route.query.code },
        });
      }

      // console.log(this.seals, this.$route.query, 111);
      // this.goBack(this.$route.query.code);
    },
    contractTemplateField (content) {
      const str = (
        content.match(
          /<input\s+type="button"\s+value="\[[A-Za-z0-9_\-\u4e00-\u9fa5]+\]"\s+data-type="[a-zA-Z0-9]+"\s+data-label="[A-Za-z0-9_\-\u4e00-\u9fa5]+"\s*\/>/g
        ) || []
      ).join('');
      const reg = /[a-z\-A-Z]+="[A-Za-z0-9_\-\u4e00-\u9fa5]+"/g;
      let result;
      const list = [];
      while ((result = reg.exec(str))) {
        if (result) {
          result = result.toString();
          if (/type="button"/.test(result)) {
            list.push({});
          } else {
            result = result
              .toString()
              .replace(/data-|"/g, '')
              .split('=');
            list[list.length - 1][result[0]] = result[1];
          }
        }
      }
      return _.uniqWith(list, _.isEqual);
    },
    async loadContractTemplate () {
      const contract = await ContractTemplateDetail({
        code: this.$route.query.code
      });
      this.signers = this.contractTemplateField(contract.content)
        .filter(it => it.type === 'subject')
        .map(it => ({
          name: `[${it.label}]`,
          account: `[${it.label}]`
        }));
      try {
        this.seals = JSON.parse(contract.config);
      } catch (error) {
        console.log(this.seals);
      }
      console.log(contract);

      const path = await ContractTemplateView({
        content: contract.content
      });
      console.log(path);
      this.files = [
        {
          name: '模板.pdf',
          url: path,
          fileId: 'file_index_0'
        }
      ];
    },
    async loadContract () {
      const contract = await ContractDetail({ code: this.$route.query.code });
      console.log(contract);
      console.log(this.parseSigner(contract));
      try {
        this.seals = JSON.parse(contract.config);
      } catch (error) {
        console.log(error);
      }
      if (contract.filePaths) {
        this.files = contract.filePaths
          .split(',')
          .map(parseFilePath)
          .map((it, index) => ({
            ...it,
            fileId: `file_index_${index}`
          }));
      }

      this.signers = this.parseSigner(contract).map(it => ({
        name: it.subjectName,
        account: it.subjectAccount
      }));
    },
    parseSigner (data) {
      const subjectAccounts = data.subjectAccounts.split(',');
      const subjectNames = data.subjectNames.split(',');
      const subjectTypes = data.subjectTypes.split(',');
      const signerAccounts = data.signerAccounts.split(',');
      const signerNames = data.signerNames.split(',');
      const len = subjectAccounts.length;
      console.log(len, subjectNames.length, subjectTypes.length, signerAccounts.length, signerNames.length);
      if (
        subjectNames.length != len ||
        subjectTypes.length != len ||
        signerAccounts.length != len ||
        signerNames.length != len
      ) {
        alert('签署人配置有误');
      } else {
        return subjectAccounts.map((it, index) => {
          return {
            subjectAccount: it,
            subjectName: subjectNames[index],
            subjectType: subjectTypes[index],
            signerAccount: signerAccounts[index],
            signerName: signerNames[index]
          };
        });
      }
    },
    loadPdf (file) {
      this.files = this.files.map(it => {
        return {
          ...it,
          isSelect: it.fileId === file.fileId ? true : false
        };
      });
      this.currentFile = file;

      let loadingTask = pdf.createLoadingTask(file.url);


      loadingTask.promise
        .then(pdf => {
          this.loading = false;
          pdf.getPage(1).then(page => {
            this.pageWidth = page.getViewport(1).viewBox[2];
            this.pageHeight = page.getViewport(1).viewBox[3];
            this.numPages = pdf.numPages;
            this.currentPage = 1;
          });
        })
        .catch(err => {
          console.error('pdf 加载失败', err);
        });
    },

    sealMousemove ($event) {
      const sealHalf = this.sealWidth / 2;
      if (this.activeSeals) {
        this.activeSeals.x = Math.min(
          Math.max(this.activeSeals.x + $event.movementX, sealHalf),
          this.pageWidth - sealHalf
        );
        this.activeSeals.y = Math.min(
          Math.max(this.activeSeals.y - $event.movementY, sealHalf),
          this.pageHeight - sealHalf
        );
        console.log(this.activeSeals.y);
      }
    },
    handleCurrentChange (val) {
      this.currentPage = val;
    },
    delSeal (item) {
      this.seals = this.seals.filter(it => it != item);
    },
    allowDrop (ev) {
      ev.preventDefault();
    },
    drag (ev, item, type) {
      ev.dataTransfer.setData('type', type);
      ev.dataTransfer.setData('data', JSON.stringify(item));
    },
    drop (ev) {
      ev.preventDefault();
      var type = ev.dataTransfer.getData('type');
      var data = ev.dataTransfer.getData('data');
      try {
        const d = JSON.parse(data);
        this.seals.push({
          subjectAccount: d.account,
          subjectName: d.name,
          x: ev.offsetX,
          y: this.pageHeight - ev.offsetY,
          fileId: this.currentFile.fileId,
          page: type === '1' ? this.currentPage : `1-${this.numPages}`,
          type: type
        });
      } catch (error) {
        console.log(error);
      }
    },
    goBack (obj) {
      // window.history.go(-1);
      console.log(obj);
      if (this.type === 'template') {
        this.$router.push({
          path: '/system/contract/contractTemplate',
          query: {
            code: this.$route.query.code
          },
        });
        console.log(111);
      }
      else {
        this.$router.push({
          path: '/system/contract/add',
          query: {
            code: this.$route.query.code
          },
        });

      }

    }
  }
};
</script>

<style>
.content {
  border: 1px #d3d3d3 solid;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  margin: 0 auto;
  position: relative;
  margin-top: 2px;
}
.seal {
  width: 77px;
  height: 77px;
  border-radius: 50%;
  border: 3px solid red;
  position: absolute;
  top: 0;
  text-align: center;
  color: red;
  user-select: none;
  cursor: move;
  font-size: 14px;
  display: none;
  transform: translate(-50%, -50%);
}
.seal2 {
  transform: translate(0, -50%);
}
.seal-title {
  font-size: 10px;
  position: relative;
  bottom: -39px;
  /* color: #000; */
  background: #fff;
  /* border: 1px solid #666; */
  color: #777;
  border: 1px solid #cecece;
}
.sealAll {
  display: block;
  right: -40px !important;
  left: initial !important;
  border-radius: 0;
}
.sealShow {
  display: block;
  right: 0;
}
ul {
  list-style-type: none;
}
h2 {
  font-size: 14px;
  margin-bottom: 8px;
  color: #666;
}
h3 {
  font-size: 14px;
  margin-bottom: 6px;
  color: #777;
  font-weight: normal;
}
.file-list {
  margin-bottom: 30px;
}
.file-list > div {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}
.file-list > div:hover {
  cursor: pointer;
}
.file-list > div.active {
  color: #008cff;
}
.drag-box {
  font-size: 12px;
  color: #333;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.drag-box > span {
  cursor: pointer;
  margin-right: 30px;
  color: red;
}
.cricleSeal {
  border: 2px solid red;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
}
.crossSeal {
  border: 2px solid red;
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
}
</style>
